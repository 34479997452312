.workflow-editor-container {
    height: 100vh;
    width: 100vw;
}

.save-workflow-button{
  position:fixed;
  bottom:35px;
  right:250px;
  display:block;
  white-space:nowrap;
  overflow:hidden;
  z-index: 1;  
}

.run-workflow-button{
  position:fixed;
  bottom:35px;
  right:35px;
  display:block;
  white-space:nowrap;
  overflow:hidden;
  z-index: 1;  
}

.desc {
  position: fixed;
  padding: 1rem 1rem;
  font-size: 1rem;
  z-index: 1;
}

.node {
  background: #0f62fe;  
  color: white;
  border: 0;
  border-radius: 2%;
}

.socket.string{
  background: #2e64c7;
}

.warning-bg {
  opacity: 0.5;
}

.warning {
  position:fixed;
  left:25%;
  top:50%;
  width: 50%;
  display:block;
  white-space:nowrap;
  overflow:hidden;
  z-index: 1;  
}