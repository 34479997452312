/* Hooks button */
.login-container{
  align-items: left;
  padding: 3rem;
}

.login-text{
  padding: 0 0 2rem 0;
  font-size: 3rem;
}

.button {
  cursor: pointer;
  margin-top: 1rem;
}

.background{
  float: right;
  img{
    overflow: hidden;
  }
}

.content{
  padding-left: 10rem;
  padding-top: 10rem;
  position: absolute;
  z-index: 1;
}
  
.buttonText {
  padding-left: 2rem;
}

.bold{
  font-weight: 700;
}
  
.icon {
  height: 25px;
  width: 25px;
  left: 30px;
  align-items: center;
  background: #fff;
  border-radius: 50%;
}

.profile-container{
  align-items: left;
  padding: 3rem;
  z-index: 1;

  p{
    padding-bottom: 0.5rem;
  }

}

.profile-pic{
  border-radius: 50%;
  margin-bottom: 1rem;
}

   
   